/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-07-21 13:44:55
 * @LastEditTime: 2022-08-03 16:09:45
 */
const routes = [{
	path: '/pc/activity',
	name: 'pcActivity',
	component: () => import('@/views/pc/activity'),
	meta: {
		title: '岗位管理',
		hidden: false,
		identityPc: "通用"
	}
}, {
	path: '/pc/manHour',
	name: 'pcManHour',
	component: () => import('@/views/pc/manHour'),
	meta: {
		title: '工时管理',
		hidden: false,
		identityPc: "通用"
	}
}, {
	path: '/pc/notice',
	name: 'pcNotice',
	component: () => import('@/views/pc/notice'),
	meta: {
		title: '公告管理',
		hidden: false,
		identityPc: "干部"
	}
}, {
	path: '/pc/account',
	name: 'pcAccount',
	component: () => import('@/views/pc/account'),
	meta: {
		title: '账号管理',
		hidden: false,
		identityPc: "干部"
	}
}, ]
export default routes
