const routes = [{
		path: '/mb/activity',
		name: 'mbActivity',
		component: () => import('@/views/mobile/activity/index'),
		meta: {
			title: '岗位报名',
			identityMb: "学生",
			hidden: false,
			keepAlive:true,
			activeIcon: require("@/assets/images/mobile/tabbar/student_active.png"),
			normalIcon: require("@/assets/images/mobile/tabbar/student_normal.png"),
		}
	}, {
		path: '/mb/manhour',
		name: 'mbManhour',
		component: () => import('@/views/mobile/manhour/index'),
		meta: {
			title: '工时管理',
			identityMb: "学校老师",
			hidden: false,
			activeIcon: require("@/assets/images/mobile/tabbar/manhour_active.png"),
			normalIcon: require("@/assets/images/mobile/tabbar/manhour_normal.png"),
		}
	}, {
		path: '/mb/student',
		name: 'mbStudent',
		component: () => import('@/views/mobile/student/index'),
		meta: {
			title: '管理',
			identityMb: "学院老师",
			hidden: false,
			activeIcon: require("@/assets/images/mobile/tabbar/teacher_active.png"),
			normalIcon: require("@/assets/images/mobile/tabbar/teacher_normal.png"),
		}
	}, {
		path: '/mb/notice',
		name: 'mbNotice',
		component: () => import('@/views/mobile/notice/index'),
		meta: {
			title: '公告',
			identityMb: "通用",
			hidden: false,
			activeIcon: require("@/assets/images/mobile/tabbar/notice_active.png"),
			normalIcon: require("@/assets/images/mobile/tabbar/notice_normal.png"),
		}
	}, {
		path: '/mb/mine',
		name: 'mbMine',
		component: () => import('@/views/mobile/mine/index'),
		meta: {
			title: '我的',
			identityMb: "通用",
			hidden: false,
			activeIcon: require("@/assets/images/mobile/tabbar/mine_active.png"),
			normalIcon: require("@/assets/images/mobile/tabbar/mine_normal.png"),
		}
	},
]
export default routes
