/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-08-04 18:20:51
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 样式统一文件
import 'normalize.css/normalize.css'
import '@/styles/index.scss'
// 全部引入element组件
import ElementUI from 'element-ui'
Vue.use(ElementUI)
// 全局图标
import './icons'
// 引入iconfont
import './assets/icon/iconfont.css'
Vue.config.productionTip = false
// 引入钉钉
import * as dd from 'dingtalk-jsapi'
Vue.prototype.$dd = dd
// 引入console
// import VConsole from "vconsole"
// new VConsole()
// 引入富文本编辑框
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
// 全局注册组件-H5
import tooltip from "./views/mobile/components/tooltip.vue"
Vue.component('tooltip', tooltip)
// 引入vant-H5
import './libs'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
