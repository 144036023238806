/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-10 10:20:32
 * @LastEditTime: 2022-07-21 10:24:19
 */
import storage from 'store'
import {
    loginStudentInMb,
    loginSchoolTeacherInMb,
    userinfoInMb,
    userLogoutMb,
} from '@/api/mobile/user'
const mbUser = {
    state: {
        mbToken: '',
        mbTokenKey: "",
        mbInfo: {},
        roles: []
    },
    mutations: {
        SET_TOKEN: (state, intToken) => {
            state.mbToken = intToken
            storage.set('accessToken', intToken, 7 * 24 * 60 * 60 * 1000)
        },
        SET_TOKEN_KEY: (state, intTokenKey) => {
            state.mbTokenKey = intTokenKey
            storage.set('accessTokenKey', intTokenKey, 7 * 24 * 60 * 60 * 1000)
        },
        SET_INFO: (state, intInfo) => {
            state.pcInfo = intInfo
            storage.set('accessInfo', intInfo, 7 * 24 * 60 * 60 * 1000)
        },
    },
    actions: {
        mbLogin({
            commit
        }, intParams) {
            return new Promise((resolve, reject) => {
                if (intParams.userType == 'STUDENT') {
                    console.log("学生登录Api")
                    loginStudentInMb(intParams).then((res) => {
                        commit('SET_TOKEN', res.data.tokenValue)
                        commit('SET_TOKEN_KEY', res.data.tokenName)
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    })
                } else {
                    console.log("学校老师登录Api")
                    loginSchoolTeacherInMb(intParams).then((res) => {
                        commit('SET_TOKEN', res.data.tokenValue)
                        commit('SET_TOKEN_KEY', res.data.tokenName)
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    })
                }
            })
        },
        mbUserInfo({
            commit
        }) {
            return new Promise((resolve, reject) => {
                userinfoInMb().then((res) => {
                    console.log("用户信息", res.data)
                    commit('SET_INFO', res.data)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        mbLogout({
            commit
        }) {
            return new Promise((resolve, reject) => {
                commit('SET_TOKEN', '')
                commit('SET_TOKEN_KEY', '')
                commit('SET_INFO', {})
                storage.remove("identityMb")
                resolve()
                // userLogoutMb().then((res) => {
                // 	commit('SET_TOKEN', '')
                // 	commit('SET_TOKEN_KEY', '')
                // 	commit('SET_INFO', {})
                // 	storage.remove("identityMb")
                // 	resolve(res)
                // }).catch((err) => {
                // 	reject(err)
                // })
            })
        }
    }
}
export default mbUser
