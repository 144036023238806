/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-10 10:20:32
 * @LastEditTime: 2022-07-21 10:24:19
 */
import storage from 'store'
import {
    loginInPc,
    userinfoInPc,
    userLogoutPc,
} from '@/api/pc/user'
const pcUser = {
    state: {
        pcToken: '',
        pcInfo: {},
        roles: []
    },
    mutations: {
        SET_TOKEN: (state, intToken) => {
            state.pcToken = intToken
            storage.set('accessToken', intToken, 7 * 24 * 60 * 60 * 1000)
        },
        SET_TOKEN_KEY: (state, intTokenKey) => {
            state.mbTokenKey = intTokenKey
            storage.set('accessTokenKey', intTokenKey, 7 * 24 * 60 * 60 * 1000)
        },
        SET_INFO: (state, intInfo) => {
            state.pcInfo = intInfo
            storage.set('accessInfo', intInfo, 7 * 24 * 60 * 60 * 1000)
        },
        SET_ROLES: (state, intRoles) => {
            state.roles = intRoles
            storage.set('ACCESS_ROLES', intRoles, 7 * 24 * 60 * 60 * 1000)
        },
    },
    actions: {
        pcLogin({
            commit
        }, intParams) {
            return new Promise((resolve, reject) => {
                loginInPc(intParams).then((res) => {
                    commit('SET_TOKEN', res.data.tokenValue)
                    commit('SET_TOKEN_KEY', res.data.tokenName)
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        pcUserInfo({
            commit
        }) {
            return new Promise((resolve, reject) => {
                userinfoInPc().then((res) => {
                    console.log("用户信息", res.data)
                    commit('SET_INFO', res.data)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        pcLogout({
            commit
        }) {
            return new Promise((resolve, reject) => {
                commit('SET_TOKEN', '')
                commit('SET_TOKEN_KEY', "")
                commit('SET_INFO', {})
                storage.remove("identityPc")
                storage.remove("localRouterPc")
                resolve()
                // userLogoutPc().then((res) => {
                // 	commit('SET_TOKEN', '')
                // 	commit('SET_TOKEN_KEY', "")
                // 	commit('SET_INFO', {})
                // 	storage.remove("identityPc")
                // 	storage.remove("localRouterPc")
                // 	resolve(res)
                // }).catch((err) => {
                // 	reject(err)
                // })
            })
        }
    }
}
export default pcUser
