<template>
	<div v-if="showTooltip" class="tooltip-shadow">
		<div class="tooltip-inner">
			<div class="tooltip-title">
				{{tooltipTitle}}
			</div>
			<div class="tooltip-content">
				{{tooltipContent}}
			</div>
			<div class="tooltip-btn">
				<span @click="cancle" v-if="showCancle" class="tooltip-btn-cancel">{{cancleText}}</span>
				<span @click="confirm" v-if="showConfirm" class="tooltip-btn-confirm">{{confirmText}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			isShowToolTip: {
				type: Boolean,
				default: function() {
					return false
				}
			},
			tooltipTitle: {
				type: String,
				default: function() {
					return ""
				}
			},
			tooltipContent: {
				type: String,
				default: function() {
					return ""
				}
			},
			showCancle: {
				type: Boolean,
				default: function() {
					return true
				}
			},
			showConfirm: {
				type: Boolean,
				default: function() {
					return true
				}
			},
			cancleText: {
				type: String,
				default: function() {
					return "取消"
				}
			},
			confirmText: {
				type: String,
				default: function() {
					return "确定"
				}
			},
		},
		watch: {
			isShowToolTip: {
				handler(val) {
					this.showTooltip = val
				},
				deep: true
			}
		},
		data() {
			return {
				showTooltip: false
			}
		},
		methods: {
			cancle() {
				this.$emit("cancle")
			},
			confirm() {
				this.$emit("confirm")
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tooltip-shadow {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;

		.tooltip-inner {
			width: 5.36rem;
			height: 2.8rem;
			background: #FFFFFF;
			border-radius: 0.16rem;
			display: flex;
			flex-direction: column;
			.tooltip-title {
				font-size: 0.32rem;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 0.44rem;
				color: #303133;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0.4rem 0 0.2rem 0;
			}
			.tooltip-content {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.4rem;
				color: #606266;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0rem 0 0.48rem 0;
			}
			.tooltip-btn {
				display: flex;
				align-items: center;
				border-top: 0.02rem solid #DCDFE6;
				.tooltip-btn-cancel {
					flex: 1;
					font-size: 0.34rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.86rem;
					color: #909399;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.tooltip-btn-confirm {
					flex: 1;
					font-size: 0.34rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.86rem;
					color: #0091FF;
					display: flex;
					align-items: center;
					justify-content: center;
					border-left: 0.02rem solid #DCDFE6;
				}
			}
		}
	}
</style>
