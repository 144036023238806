import Vue from 'vue'
import {
	DatetimePicker,
	Picker,
	PullRefresh,
	List,
	Dialog,
	Toast,
} from "vant"
Vue.use(DatetimePicker).use(Picker).use(PullRefresh).use(List).use(Dialog).use(Toast)
