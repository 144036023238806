import axios from '@/utils/request'
let Api = {
	userLoginStudent: "/student/login",
	userLoginSchoolTeacher: "/login",
	userInfo: "/getUserInfo",
	userLogout: "/logout",
	getConfigCode:"/getConfig"
}

// 学生登录
export function loginStudentInMb(params) {
	return axios({
		method: 'post',
		url: Api.userLoginStudent,
		data: params
	})
}
// 学校老师登录
export function loginSchoolTeacherInMb(params) {
	return axios({
		method: 'post',
		url: Api.userLoginSchoolTeacher,
		data: params
	})
}
// 用户信息
export function userinfoInMb(params) {
	return axios({
		method: 'get',
		url: Api.userInfo,
		params: params,
	})
}
// 退出登录
export function userLogoutMb(params) {
	return axios({
		method: 'get',
		url: Api.userLogout,
		params: params,
	})
}
//获取code
export function getConfigCode(params) {
	return axios({
		method: 'get',
		url: Api.getConfigCode,
		params: params,
	})
}