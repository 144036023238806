/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-27 18:15:12
 * @LastEditTime: 2022-06-23 18:15:54
 */
import axios from 'axios'
import storage from "store";
import store from '@/store'
import router from '@/router'
import {
	Message,
	Notification
} from 'element-ui'
import {
	VueAxios
} from './axios'
import {
	Toast
} from 'vant';
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const request = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 60000
})
const isMobileVerify = () => {
	// console.log("navigator", navigator.userAgent);
	let flag = navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	);
	return flag;
}
// 异常拦截处理器
const errorHandler = (error) => {
	if (error.response) {
		const data = error.response.data
		console.log(data,'datatatatcode')
		const token = storage.get('accessToken')
		if (data.code == '4001') {
			if (token) {
				if (isMobileVerify()) {
					console.log("手机端-request.js");
					Toast({
						message: "登录过期，请重新登录~"
					})
					store.dispatch("mbLogout").then(() => {
						router.push({
							path: `/mb/identity`
						})
					});
				} else {
					console.log("pc端-request.js");
					Message({
						message: "登录过期，请重新登录~",
						type: 'error',
						duration: 1500
					})
					store.dispatch("pcLogout").then(() => {
						router.push({
							path: `/pc/user/login`
						})
					});
				}
			}
		}  else {
			if (isMobileVerify()) {
				Toast({
					message: data.msg
				})
			} else {
				Message({
					message: data.msg,
					type: 'error',
					duration: 1500
				})
			}
		}
		// else if(data.code=='5000'){
		// 	if (isMobileVerify()) {
		// 		Toast({
		// 			message: data.msg
		// 		})
		// 		setTimeout(()=>{
		// 			router.push({
		// 				path: `/mb/identity`
		// 			})
		// 		},1000)
		// 	}else{
		// 		Message({
		// 			message: data.msg,
		// 			type: 'error',
		// 			duration: 1500
		// 		})
		// 		setTimeout(()=>{
		// 			router.push({
		// 				path: `/pc/user/identity`
		// 			})
		// 		},1000)
		// 	}
		// }
	}
	return Promise.reject(error)
}

// 请求拦截器
request.interceptors.request.use(config => {
	const token = storage.get('accessToken')
	// console.log('token>>>>', token, process.env.NODE_ENV)
	if (token) {
		config.headers[`${storage.get('accessTokenKey')}`] = token
	}
	return config
}, errorHandler)

// 响应拦截器
request.interceptors.response.use(res => {
	console.log('响应拦截器>>>>1', res)
	const code = res.data.code
	if (code && code != '0') {
		const token = storage.get('accessToken')
		console.log('code', code)
		if (code == '4001') {
			if (token) {
				if (isMobileVerify()) {
					console.log("手机端-request.js");
					Toast({
						message: "登录过期，请重新登录~"
					})
					store.dispatch("mbLogout").then(() => {
						router.push({
							path: `/mb/identity`
						})
					});
				} else {
					console.log("pc端-request.js");
					Message({
						message: "登录过期，请重新登录~",
						type: 'error',
						duration: 1500
					})
					store.dispatch("pcLogout").then(() => {
						router.push({
							path: `/pc/user/identity`
						})
					});
				}
			}
		}else {
			if (isMobileVerify()) {
				Toast({
					message: res.data.msg
				})
			} else {
				Message({
					message: res.data.msg,
					type: 'error',
					duration: 1500
				})
			}
		}
	}
	return res.data
}, errorHandler)
const installer = {
	vm: {},
	install(Vue) {
		Vue.use(VueAxios, request)
	}
}

export default request

export {
	installer as VueAxios,
	request as axios
}
