/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-08-03 16:27:47
 */
import Vue from 'vue'
import pcRouter from './pcRouter'
import mobileRouter from './mobileRouter'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    // PC路由
    {
        path: '/',
        type: 'pc',
        component: () => import('@/layout/pc'),
        redirect: '/pc/activity',
        children: [
            ...pcRouter
        ]
    },
    {
        path: '/',
        type: 'pc',
        component: () => import('@/layout/pcRouteView'),
        children: [{
            path: '/pc/user/identity',
            name: 'pcIdentity',
            component: () => import('@/views/pc/user/identity'),
            meta: {
                title: '选择身份',
                hidden: false,
				showHeader: false
            }
        },{
            path: '/pc/user/login',
            name: 'pcLogin',
            component: () => import('@/views/pc/user/login'),
            meta: {
                title: '登录',
                hidden: false,
				showHeader: false
            }
        },{
            path: '/pc/activity/addJob',
            name: 'pcAddJob',
            component: () => import('@/views/pc/activity/addJob'),
            meta: {
				parentPath: "/pc/activity",
                title: '岗位新增',
                hidden: false,
				showHeader: true
            }
        },{
            path: '/pc/activity/jobDetail',
            name: 'pcJobDetail',
            component: () => import('@/views/pc/activity/jobDetail'),
            meta: {
				parentPath: "/pc/activity",
                title: '岗位详情',
                hidden: false,
				showHeader: true
            }
        },{
            path: '/pc/notice/noticeAdd',
            name: 'pcNoticeAdd',
            component: () => import('@/views/pc/notice/noticeAdd'),
            meta: {
				parentPath: "/pc/notice",
                title: '新增公告',
                hidden: false,
				showHeader: true
            }
        },{
            path: '/pc/rejectList',
            name: 'pcRejectList',
            component: () => import('@/views/pc/rejectList'),
            meta: {
				parentPath: "/pc/activity",
                title: '驳回通知',
                hidden: false,
				showHeader: true
            }
        }]
    },
    // 移动端路由
    {
        path: '/',
        type: 'mobile',
        component: () => import('@/layout/mobile'),
        children: [
            ...mobileRouter
        ]
    },
    {
        path: '/',
        type: 'mobile',
        component: () => import('@/layout/mbRouteView'),
        children: [{
                path: '/mb/identity',
                name: 'mbIdentity',
                component: () => import('@/views/mobile/identity/index'),
                meta: {
                    title: '身份选择',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/user/login',
                name: 'mbLogin',
                component: () => import('@/views/mobile/user/login'),
                meta: {
                    title: '登录',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/activity/applyed',
                name: 'mbActivityApplyed',
                component: () => import('@/views/mobile/activity/applyed'),
                meta: {
                    title: '已报名岗位',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/activity/jobhour',
                name: 'mbActivityJobhour',
                component: () => import('@/views/mobile/activity/jobhour'),
                meta: {
                    title: '岗位总工时',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/activity/detail',
                name: 'mbActivityDetail',
                component: () => import('@/views/mobile/activity/detail'),
                meta: {
                    title: '岗位详情',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/notice/detail',
                name: 'mbNoticeDetail',
                component: () => import('@/views/mobile/notice/detail'),
                meta: {
                    title: '详情',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/mine/personal',
                name: 'mbPersonal',
                component: () => import('@/views/mobile/mine/personal'),
                meta: {
                    title: '个人资料',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/mine/inform',
                name: 'mbInform',
                component: () => import('@/views/mobile/mine/inform'),
                meta: {
                    title: '消息通知',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/mine/password',
                name: 'mbPassword',
                component: () => import('@/views/mobile/mine/password'),
                meta: {
                    title: '修改密码',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/mine/schedule',
                name: 'mbSchedule',
                component: () => import('@/views/mobile/mine/schedule'),
                meta: {
                    title: '我的课表',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/mine/resume',
                name: 'mbResume',
                component: () => import('@/views/mobile/mine/resume'),
                meta: {
                    title: '简历上传',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/mine/resumehistory',
                name: 'mbResumeHistory',
                component: () => import('@/views/mobile/mine/resumehistory'),
                meta: {
                    title: '简历记录',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/manhour/detail',
                name: 'mbManhourDetail',
                component: () => import('@/views/mobile/manhour/detail'),
                meta: {
                    title: '详情',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/manhour/edithour',
                name: 'mbManhourEdithour',
                component: () => import('@/views/mobile/manhour/edithour'),
                meta: {
                    title: '工时编辑',
                    hidden: true,
                    showHeader: true
                }
            },
            {
                path: '/mb/mine/singin',
                name: 'mbMineSingin',
                component: () => import('@/views/mobile/mine/singin'),
                meta: {
                    title: '签到/签退',
                    hidden: true,
                    showHeader: true
                }
            },
        ]
    }
]
const router = new VueRouter({
    mode: 'hash',
    routes
})

export default router
