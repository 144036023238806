<template>
	<div class="app">
		<!-- <keep-alive v-if="isRouterAlive">
			<router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view> -->
		<router-view></router-view>
	</div>
</template>

<script>
	import storage from 'store'
	import router from './router'
	import {
		getConfigCode
	} from "@/api/mobile/user";
	import {
		mapActions
	} from 'vuex'
	export default {
		name: 'App',
		// provide(){
		// 	return {reload:this.reload}
		// },
		data() {
			return {
				identityType:'',
				isRouterAlive:true
			}
		},
		mounted() {
			console.log(this.isMobileVerify())
			if (this.isMobileVerify()) {
				console.log("手机端-App.vue");
				if (storage.get("accessToken")) {
					let userInfo = storage.get("accessInfo")
					if (userInfo.userType == 'STUDENT') {
						this.$router.replace("/mb/activity");
					} else {
						this.$router.replace("/mb/manhour");
					}
				} else {
					//测试
					this.$router.replace("/mb/user/login");
					// let code = this.$route.query.code
					// console.log(code,'codecode')
					// if(code){
					// 	storage.set("code",code)
					// 	this.login(code)
					// }else{
					// 	getConfigCode().then(res=>{
					// 		if(res.code == 0){
					// 			var pathUrl= "https://www.57qingong.zjut.edu.cn/#/mb/activity/index"
					// 			const uri = encodeURIComponent(pathUrl)
					// 			const REDIRECT_URI =res.data.baseUrl+'/cas/oauth2.0/authorize?response_type=code&client_id='+res.data.appKey+'&redirect_uri='+uri
					// 			location.href = REDIRECT_URI
					// 		}
					// 	})
					// }
				}
			} else {
				console.log("pc端-App.vue");
				if (storage.get("accessToken")) {
					let localRouterPc = storage.get("localRouterPc")
					if(localRouterPc) {
						this.$router.replace(localRouterPc);
					} else {
						this.$router.replace("/pc/activity");
					}
				} else {
					this.$router.onReady(()=>{
						console.log("该用户未登录codem")
						let code = this.$route.query.code
						if(code){
							storage.set("code",code)
							this.pcLogin(code)
						}else{
							console.log("该用户未登录")
							this.$router.replace("/pc/user/login");
						}
					})
					// this.$router.replace("/pc/user/identity");
				}
			}
		},
		methods: {
			...mapActions(['mbLogin', 'mbUserInfo','pcUserInfo']),
			login(code){
				let params = {
					code:code,
					plat:1,
				}
				this.mbLogin(params).then(async (res) => {
					if (res.code == 0) {
						await this.mbUserInfo()
						let userInfo = storage.get("accessInfo")
						let typeMap = {
							"STUDENT": "学生",
							"SCHOOL_MANAGER": "干部",
							"COLLEGE_MANAGER": "学院老师",
							"SCHOOL_TEACH": "学校老师",
						}
						storage.set("identityMb", typeMap[userInfo.userType])
						if(userInfo.userType=='STUDENT'){
							this.$router.replace('/mb/activity')
						}else{
							this.$router.replace('/mb/manhour')
						}
					}
					// else{
					// 	this.$router.replace("/mb/identity");
					// }
				})
			},
			pcLogin(code){
				let params = {
					code:code,
					plat:2
				}
				this.mbLogin(params).then(async (res) => {
					if (res.code == 0) {
						await this.pcUserInfo()
						let userInfo = storage.get("accessInfo")
						let typeMap = {
							"STUDENT": "学生",
							"SCHOOL_MANAGER": "干部",
							"COLLEGE_MANAGER": "学院老师",
							"SCHOOL_TEACH": "学校老师",
						}
						storage.set("identityPc", typeMap[userInfo.userType])
						this.$router.push({
							path: `/pc/activity`
						})
					}else{
						this.$router.replace("/pc/user/login");
					}
				})
			},
			isMobileVerify() {
				// console.log("navigator", navigator.userAgent);
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
			reload(){
				this.isRouterAlive = false
				this.$nextTick(()=>{
					this.isRouterAlive = true
				})
			},
		},
	}
</script>

<style lang="scss">
	@import '@/styles/index.scss';

	.app {}

	.dialog-bg {
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 2025 !important;
	}

	.el-message {
		z-index: 3000 !important;
	}
	
	.el-message__icon {
		font-size: 20px;
	}
	
	.common-pageNation-pc {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 40px 0 10px 0;
	}
	
	.ql-toolbar.ql-snow {
	    border: 1px solid #DCDFE6 !important;
	}
	
	.ql-container.ql-snow {
		border: 1px solid #DCDFE6 !important;
		border-top: 0 !important;
		height: 230px;
	}
</style>
