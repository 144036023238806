/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-07-21 13:41:11
 */
import Vue from 'vue'
import Vuex from 'vuex'
import breadcrumb from './modules/breadcrumb'
// import permission from './modules/permission'
import pcUser from './modules/pcUser'
import mbUser from './modules/mbUser'
import getters from './getters'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		breadcrumb,
		// permission,
		pcUser,
		mbUser
	},
	getters
})
