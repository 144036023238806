import axios from '@/utils/request'

let Api = {
	userLogin: "/login",
	userInfo: "/getUserInfo",
	userLogout: "/logout",
	collegeList: "/college/list",
	accountList: "/userAccount/list",
	accountDelete: "/userAccount/delete/",
	accountDetail: "/userAccount/",
	accountEdit: "/userAccount/edit",
	accountAdd: "/userAccount/add",
	orgListAll:"/org/listAll",
	noReadNum:"/siteMsg/noReadNum",
	siteMsgList:"/siteMsg/list",
	siteMsgRead:"/siteMsg/read/"
}
// 登录
export function loginInPc(params) {
	return axios({
		method: 'post',
		url: Api.userLogin,
		data: params
	})
}
// 用户信息
export function userinfoInPc(params) {
	return axios({
		method: 'get',
		url: Api.userInfo,
		params: params,
	})
}
// 退出登录
export function userLogoutPc(params) {
	return axios({
		method: 'get',
		url: Api.userLogout,
		params: params,
	})
}
// 学院列表
export function collegeListPc(params) {
	return axios({
		method: 'get',
		url: Api.collegeList,
		params: params
	})
}
// 账号列表
export function accountListPc(params) {
	return axios({
		method: 'get',
		url: Api.accountList,
		params: params
	})
}
// 账号删除
export function accountDeletePc(id, params) {
	return axios({
		method: 'delete',
		url: Api.accountDelete + id,
		params: params
	})
}
// 账号详情
export function accountDetailPc(id, params) {
	return axios({
		method: 'get',
		url: Api.accountDetail + id,
		params: params
	})
}
// 账号编辑
export function accountEditPc(params) {
	return axios({
		method: 'post',
		url: Api.accountEdit,
		data: params
	})
}
// 账号新增
export function accountAddPc(params) {
	return axios({
		method: 'post',
		url: Api.accountAdd,
		data: params
	})
}
//查询部门列表
export function orgListAllPc(params) {
	return axios({
		method: 'get',
		url: Api.orgListAll,
		params: params
	})
}
//未读消息数量
export function noReadNumPc(params) {
	return axios({
		method: 'get',
		url: Api.noReadNum,
		params: params
	})
}
//消息列表
export function siteMsgListPc(params) {
	return axios({
		method: 'get',
		url: Api.siteMsgList,
		params: params
	})
}
//消息已读
export function siteMsgReadPc(params,id) {
	return axios({
		method: 'get',
		url: Api.siteMsgRead+id,
		params: params
	})
}